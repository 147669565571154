import React, { FC, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { SmartForm } from "../../../../hocs/SmartForm";
import { Button } from "../../../../ui/Button";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { VinAndLicensePlate } from "../../../../ui/VinAndLicensePlate";
import { API } from "../../../../../api";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { applicationReducer } from "../../../../../store/reducers/application";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { LoaderPage } from "../../../../ui/LoaderPage";
import { useFetchCar } from "../../../../../hooks/logical/useFetchCar";
import { sendMetrics } from "../../../../../metrics";
import { Icons } from "assets/icons";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { InformationDropdown } from "../Phone/InformationDropdown";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { useScrollToTop } from "../../../../../hooks/logical/useScrollToTop";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import TagManager from "react-gtm-module";

type formType = {
  reg_number: string;
  vin_number: string;
  vin_or_license_plate_selected: boolean | undefined;
};

type propsType = {
  width?: number;
};

const title = [
  "До 70% от цены автомобиля",
  "До 500 000 р на карту",
  "Срок 24-48 месяцев",
  "Без визита в офис",
  "Вероятность одобрения до 90%",
];

const dropdownItems = [
  {
    title: "Что предлагает Creddy?",
    variant: "white",
    className: styles.inf_drop_container_first,
    shiftDownStyles: styles.shiftDown_first,
    titleFooter: (
      <>
        Мы предлагаем получение займа до 500 000 ₽ под залог автомобиля.
        <br /> <br />
        Наши клиенты регистрируются на сайте, отправляют нам фотографии
        документов и авто <br /> и получают решение в течение 30 минут. Все
        онлайн и никуда не надо ехать.
      </>
    ),
  },
  {
    title: "Какие требования к заёмщику и автомобилю?",
    variant: "white",
    className: styles.inf_drop_container,
    shiftDownStyles: styles.shiftDown_second,
    titleFooter: (
      <>
        Гражданство РФ, возраст от 21 года
        <br /> <br />
        Легковые автомобили категории B, легкий коммерческий транспорт и
        грузовые автомобили:
        <br />
        <br />
        <ul>
          <li>Отечественные - не старше 10 лет</li>
          <li>Иномарки - не старше 20 лет</li>
        </ul>
      </>
    ),
  },
  {
    title: "Какие документы необходимы для получения займа?",
    variant: "white",
    className: styles.inf_drop_container,
    shiftDownStyles: styles.shiftDown_third,
    titleFooter: (
      <>
        <ul>
          <li>Паспорт гражданина РФ</li>
          <li>Паспорт транспортного средства (ПТС)</li>
          <li>Свидетельство о регистрации автомобиля (СТС)</li>
        </ul>
      </>
    ),
  },
  {
    title: "Нужно ли куда-то ехать?",
    variant: "white",
    className: styles.inf_drop_container_last,
    shiftDownStyles: styles.shiftDown_fourth,
    titleFooter: (
      <>
        Нет, ехать никуда не нужно. Осмотр автомобиля производится посредством
        видеозвонка
      </>
    ),
  },
  {
    variant: "grey",
  },
];

export const VehicleValuation: FC<propsType> = (props) => {
  const [isLoadingFetchCar, setIsLoadingFetchCar] = useState(false);

  const dispatch = useDispatch();

  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const phoneNumber = useSelector(userReducerSelectors.getPhone);

  const history = useHistory();

  const reg_number = useWatch({
    control: form.control,
    name: "reg_number",
  });

  const vin = useWatch({
    control: form.control,
    name: "vin_number",
  });

  const isChecked = useWatch({
    control: form.control,
    name: "vin_or_license_plate_selected",
  });

  const fetchCar = useFetchCar(
    // reg_number,
    // vin,
    phoneNumber,
    history,
    setIsLoadingFetchCar
  );

  const loading = useSelector(
    processLoading.get(applicationSagaActions.createApplicationShort.type)
  );

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.key === "Enter" && loading) || isLoadingFetchCar) {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [loading, isLoadingFetchCar]);

  const submit = (data: formType) => {
    if (!isChecked) {
      if (reg_number?.length >= 8) {
        setIsLoadingFetchCar(true);
        lsController.set("grz", reg_number);
        dispatch(
          applicationReducer.actions.setCar({
            car: { vin, reg_number },
          })
        );
        fetchCar(reg_number);
        TagManager.dataLayer({
          dataLayer: {
            event: "buttonClick",
            buttonLabel: "Example Button",
          },
        });
      } else {
        form.setError("reg_number", {
          type: "custom",
          message: "3аполните поле",
        });
      }
    }
    if (isChecked) {
      if (vin?.length === 17) {
        setIsLoadingFetchCar(true);
        lsController.set("vin", vin);
        dispatch(
          applicationReducer.actions.setCar({
            car: { vin, reg_number },
          })
        );
        fetchCar(vin);
        TagManager.dataLayer({
          dataLayer: {
            event: "buttonClick",
            buttonLabel: "Example Button",
          },
        });
      } else {
        form.setError("vin_number", {
          type: "custom",
          message: "3аполните поле",
        });
      }
    }
  };

  useEffect(() => {
    form.clearErrors("reg_number");
    form.clearErrors("vin_number");
  }, [isChecked]);

  useEffect(() => {
    sendMetrics("vehicle_valuation");
    lsController.clearGRZ();
    lsController.clearVIN();
  }, []);

  return (
    <div className={styles.container}>
      <SmartForm
        className={styles.main_information_container}
        form={form}
        submit={submit}
      >
        <div className={styles.subtitle_container}>
          <div className={styles.title_vehicle}>
            Онлайн-займ <br />
            под залог авто{" "}
          </div>
          {/*<div className={styles.subtitle_text}>Спасибо, что выбрали нас!</div>*/}
          <div className={styles.ul_container}>
            {title.map((item, index) => {
              return (
                <ul className={styles.ul_bullet} key={index}>
                  <li>{item}</li>
                </ul>
              );
            })}
          </div>
          <div className={styles.comments}>
            Сначала давайте оценим <br />
            ваш автомобиль по госномеру <br />
            или VIN!
          </div>
        </div>
        <VinAndLicensePlate
          setError={form.setError}
          className={styles.vin_and_license_plate}
        />
        <Button
          loading={isLoadingFetchCar || loading}
          className={styles.button}
          isDisabled={
            (!isChecked && !reg_number) ||
            (isChecked && !vin) ||
            (!isChecked && reg_number.length < 8) ||
            (isChecked && vin.length < 17)
          }
        >
          Продолжить
        </Button>
        <div
          className={styles.link_lc}
          onClick={() => history.push(routes.auth.personal_cabinet)}
        >
          <div className={styles.link_text}>У меня уже есть личный кабинет</div>
          <Icons.ui.LinkToPC className={styles.img} />
        </div>
        {props.width && props.width < 1023 && (
          <>
            <footer className={styles.footer_container2}>
              <div className={styles.question_title}>
                Ответы на частые вопросы
              </div>
              {dropdownItems.map((item: any, index) => (
                <InformationDropdown
                  key={index}
                  className={item.className}
                  variant={item.variant}
                  title={item.title}
                  titleFooter={item.titleFooter}
                  shiftDownStyles={item.shiftDownStyles}
                />
              ))}
            </footer>
            <FillingAssistance className={styles.assistance_container} />
          </>
        )}
      </SmartForm>
    </div>
  );
};
