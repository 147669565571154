import styles from "./index.module.css";
import React from "react";
import { UseFormReturn } from "react-hook-form/dist/types";
import { createFC } from "../../../../../helpers/createFC";
import { Clock } from "../../../../ui/Clock";

type propsType = {
  form: UseFormReturn<any>;
  primaryColor?: "blue";
};

export const TimeBlock = createFC(({ form, primaryColor }: propsType) => {
  return (
    <div className={styles.time}>
      <div className={styles.title}>Выберите удобное время</div>
      <div className={styles.subtitle}>
        Позвоним и проверим, что займ берёте именно Вы
      </div>
      <div className={styles.subtitle_small}>
        Укажите время по московскому часовому поясу
      </div>
      <div className={styles.clock}>
        <Clock form={form} primaryColor={primaryColor} />
      </div>
    </div>
  );
});
