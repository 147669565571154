import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import mergeRefs from "react-merge-refs";
import cn from "classnames";
import styles from "./index.module.css";
import { useBlurred } from "../../../../hooks/logical/useBlurred";
import { Icons } from "../../../../assets/icons";
import { InputForScroll } from "../../Inputs/InputForScroll";

export type selectItemType<T> = {
  id: number;
  text: string;
  value: T;
};

/**
 * initialValue - дефолтное значение, подставляем в инпут;
 * */

type propsType<T> = {
  initialValue?: string | null;
  isCodeInputFocused?: boolean;
  division_code?: string | null;
  options?: Array<selectItemType<T>>;
  optionComponent?: FC<T>;
  selectedComponent?: FC<T>;
  control: Control<any>;
  search?: boolean;
  support?: ReactNode;
  defaultSelectValue?: selectItemType<T>;
  name: string;
  setInputValue?: (val: string) => void;
  onSelectValue?: (val: selectItemType<T>) => void;
  searchRule?: (val: T) => boolean;
  disableFiltering?: boolean;
  setValue: UseFormSetValue<any>;
  selectTitle?: string;
  isLoading?: boolean;
  customContent?: ReactNode;
  disabledIfChosen?: boolean;
  error?: string;
  clearErrors?: (name: string) => void;
  showCustomContent?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  firstSelect?: boolean;
  numberType?: boolean;
  readOnly?: boolean;
  autoCompleteName?: string;
  setFocus?: boolean;
  inputElementRef?: React.RefObject<HTMLInputElement>;
  keypressHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
} & HTMLAttributes<HTMLDivElement>;

export function Select<T>({
  initialValue,
  isCodeInputFocused,
  division_code,
  options,
  optionComponent,
  selectedComponent,
  search,
  searchRule,
  setValue: setFormValue,
  disableFiltering,
  defaultSelectValue,
  setInputValue,
  name,
  onSelectValue,
  control,
  selectTitle,
  isLoading,
  customContent,
  showCustomContent,
  error,
  clearErrors,
  onBlur,
  onFocus,
  support,
  disabledIfChosen,
  firstSelect,
  numberType = false,
  readOnly,
  autoCompleteName,
  setFocus,
  keypressHandler,
  inputElementRef,
  ...jsxAttr
}: propsType<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const selected: selectItemType<T> = useWatch({
    control,
    name: name,
  });
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useBlurred(ref, setIsOpen, isOpen);

  // Callbacks
  const onSearchValueChange = (e: any) => {
    const value = e.currentTarget.value;
    setSearchValue(value);
    setFormValue(name, undefined);
    setInputValue && setInputValue(value);
    clearErrors && clearErrors(name);
  };

  const onBlurHandler = (e: any) => {
    onBlur && onBlur(e);
    if (!e.relatedTarget || e.relatedTarget?.tagName != "LI") {
      setIsOpen(false);
    }
  };
  const onSelectProxy = (val: selectItemType<T>) => {
    setFormValue(name, val);
    onSelectValue && onSelectValue(val);
    clearErrors && clearErrors(name);
    setIsOpen(false);
  };

  const toggleSelect = () => {
    if (!(disabledIfChosen && selected?.value)) {
      setIsOpen(!isOpen);
    }
  };

  // Clear search field by toggling "isOpen" state
  useEffect(() => {
    // if (search && !isOpen) {
      setSearchValue(selected?.text);
    // }
  }, [isOpen]);

  useEffect(() => {
    if (name === "registration_address") {
      if (options?.length) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [options]);

  useEffect(() => {
    if (name === "passport.issued_by") {
      if (options?.length && division_code?.length === 7) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [options, division_code]);

  useEffect(() => {
    if (name === "passport.issued_by" && !isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      name === "passport.issued_by" &&
      !isOpen &&
      isCodeInputFocused &&
      division_code?.length === 7
    ) {
      setIsOpen(true);
    }
  }, [isOpen, isCodeInputFocused]);

  // Set default value when component did mount
  useEffect(() => {
    if (defaultSelectValue) {
      onSelectProxy(defaultSelectValue);
    }
  }, []);

  // View methods

  useEffect(() => {
    if (
      name === "registration_address" &&
      selected?.value &&
      // @ts-ignore
      !selected?.value?.data?.house
    ) {
      inputRef.current?.focus();
    }
  }, [selected]);

  useEffect(() => {
    setFocus && inputRef?.current?.focus();
  }, [setFocus]);

  useEffect(() => {
    initialValue && setSearchValue(initialValue);
  }, [initialValue]);

  const displayOptions = () => {
    if (!options?.length) return null;
    let resultOptions = [...options];
    if (search && searchValue && !disableFiltering) {
      resultOptions = resultOptions.filter((item) =>
        item.text.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return optionComponent
      ? resultOptions?.map((item) => {
          return (
            <SelectItem
              key={item.id}
              onSelect={onSelectProxy}
              payload={item}
              className={styles.company_wrapper}
            >
              {React.createElement(optionComponent, item.value)}
            </SelectItem>
          );
        })
      : resultOptions?.map((item) => {
          return (
            <SelectItem
              key={item.id}
              onSelect={onSelectProxy}
              payload={item}
              className={styles.list_item}
            >
              <div className={styles.list_item_text}>{item.text}</div>
            </SelectItem>
          );
        });
  };

  // const displayHeader = (numberType: boolean) => {
  //   if (isOpen && search) {
  //     return (
  //       <input
  //         onFocus={() => setIsOpen(true)}
  //         // onBlur={() => setIsSearchFocused(false)}
  //         autoFocus={true}
  //         type={numberType ? "number" : "text"}
  //         name={name}
  //         value={searchValue}
  //         className={styles.search_input}
  //         onChange={onSearchValueChange}
  //       />
  //     );
  //   }
  //
  //   return (
  //     selected?.value &&
  //     (selectedComponent
  //       ? React.createElement(selectedComponent, selected.value)
  //       : selected.text)
  //   );
  // };

  return (
    <div
      ref={ref}
      {...jsxAttr}
      // onBlur={onBlurHandler}
      className={cn(styles.container, jsxAttr.className)}
    >
      {/* Render main field */}
      <div
        className={cn(styles.header, {
          [styles.header_active]: isOpen,
          [styles.readonly]: readOnly,
          [styles.select_issued_by_header]: name === "passport.issued_by",

          // [styles.withoutBorder]: !isLoading && support,
        })}
        // onClick={toggleSelect}
      >
        <div
          className={cn(styles.select_title, {
            [styles.select_title_small]: selected?.value || searchValue || isOpen || inputRef.current?.value || inputRef.current === document.activeElement,
          })}
        >
          {selectTitle}
        </div>
        {/*{displayHeader(numberType)}*/}
        <input
          onFocus={() => {
            setIsOpen(true);
            onFocus && onFocus();
          }}
          // onBlur={() => setIsSearchFocused(false)}
          // autoFocus={true}
          ref={inputElementRef ? mergeRefs([inputRef, inputElementRef]) : inputRef}
          type={numberType ? "number" : "text"}
          name={autoCompleteName}
          value={searchValue}
          className={styles.search_input}
          onChange={onSearchValueChange}
          readOnly={readOnly}
          onKeyDown={keypressHandler}
          // autoComplete={"street-address"}
        />
        {error && <div className={styles.error}>! {error}</div>}

        {!firstSelect && (
          <div
            className={styles.support}
            onClick={() => {
              !isLoading && inputRef.current?.focus();
            }}
          >
            {isLoading ? (
              <Icons.ui.Loader className={styles.header_support_loading} />
            ) : support ? (
              support
            ) : null}
            {/*(*/}
            {/*<>*/}
            {/*  {!readOnly && (*/}
            {/*    <Icons.ui.arrowAngleIcon*/}
            {/*      className={cn(styles.header_support, {*/}
            {/*        [styles.header_support_open]: isOpen,*/}
            {/*      })}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</>*/}
            {/*)*/}
          </div>
        )}

        <InputForScroll control={control} name={name + ".text"} />
      </div>
      {/* Render list */}
      {!!options?.length && isOpen ? (
        <ul className={styles.list}>
          {customContent && showCustomContent
            ? customContent
            : displayOptions()}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}

const SelectItem: FC<{
  onSelect: (val: any) => void;
  payload: any;
  className?: string;
}> = (props) => {
  const onSelectHandler = () => {
    props.onSelect(props.payload);
  };

  const keyDownHandler = (e: any) => {
    let codesLikeClick = ["Space", "Enter"];
    if (codesLikeClick.includes(e.code)) {
      props.onSelect(props.payload);
    }
  };

  return (
    <li
      data-cy="select_item"
      onKeyUp={keyDownHandler}
      tabIndex={0}
      onClick={onSelectHandler}
      className={props.className}
    >
      {props.children}
    </li>
  );
};
