import React, { FC, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import styles from "./index.module.css";
import { API } from "api";
import { Icons } from "../../../../assets/icons";
import { Select } from "../Select";
import { useSearchSelect } from "../../../../hooks/api/useSearchSelect";

export function getRelevantFias(obj: any): string | null {
  const fiasKey = [
    "fias_id",
    "street_fias_id",
    "settlement_fias_id",
    "city_fias_id",
    "region_fias_id",
  ].find((key) => obj?.[key] && obj[key] != "—");

  return fiasKey ? obj[fiasKey] : null;
}

const generateCorps = (val: string) => {
  const array = val.replace("стр ", "").split(" ");
  return {
    corps: array[0],
    structure: array[1],
  };
};

/** @propsType
 * defaultValue - дефолтное значение с отправкой запроса;
 * initialValue - дефолтное значение просто передаем в селект и подставляем в инпут без отправки запроса;
 * */

type propsType = {
  defaultValue?: string | null;
  initialValue?: string | null;
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  error?: string;
  label?: string;
  clearErrors: () => void;
  readOnly?: boolean;
  onFocus?: () => void;
  setFocus?: boolean;
  inputElementRef?: React.RefObject<HTMLInputElement>;
  keypressHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};
export const GetAddressSelect: FC<propsType> = ({
  defaultValue,
  initialValue,
  control,
  name,
  onFocus,
  setFocus,
  setValue,
  error: formError,
  clearErrors,
  readOnly = false,
  label,
  keypressHandler,
  inputElementRef,
}) => {
  const value = useWatch({
    control,
    name,
  });

  const [addressFieldsName] = useState(
    Array.from(name).reverse().join("") + "Fields."
  );
  const structure =
    value?.value?.block_type == "стр" ? value?.value?.block : undefined;
  const corps =
    value?.value?.block_type == "к"
      ? generateCorps(value?.value?.block)
      : undefined;
  const construction =
    value?.value?.block_type == "соор" ? value?.value?.block : undefined;

  const { options, setInputValue, isLoading, setOptions } =
    useSearchSelect<any>({
      fetch: (val) => API.main.services.getAddress({ address: val }),
      onSuccess: (res, hookApi) => {
        if (!!res.data.data.suggestions.length) {
          const newOptions = res.data.data.suggestions.map(
            (item: any, index: number) => ({
              id: index,
              text: item.value,
              value: item,
            })
          );
          hookApi.setOptions(newOptions);
          return newOptions;
        } else {
          hookApi.setOptions([]);
        }
      },
      onError: (hookApi) => {
        hookApi.setError("Не найдено");
      },
      defaultValue: defaultValue,
      name: name,
      setValue,
    });

  const editHandler = () => {
    setValue(addressFieldsName, undefined);
    setOptions([]);
    setValue(name, value);
  };

  return (
    <div className={styles.container}>
      <Select
        control={control}
        options={options}
        search
        selectTitle={label || "Адрес"}
        setInputValue={setInputValue}
        disableFiltering
        // disabledIfChosen
        error={formError}
        clearErrors={clearErrors}
        isLoading={isLoading}
        support={
          // value?.value && !readOnly ? (
          !readOnly ? <Icons.ui.PencilBlack onClick={editHandler} /> : undefined
        }
        name={name}
        setFocus={setFocus}
        setValue={setValue}
        readOnly={readOnly}
        autoCompleteName={"street-address"}
        inputElementRef={inputElementRef}
        keypressHandler={keypressHandler}
        onFocus={onFocus}
        initialValue={initialValue}
        // defaultSelectValue={{ id: 1, value: "aaa", text: "123zxc123" }}
      />
      {options?.length === 0 && (
        <div className={styles.address_not_found}>
          <div><Icons.ui.NotFoundMagnifier /></div>
          <div>По вашему запросу ничего не найдено</div>
        </div>
      )}
    </div>
  );
};
