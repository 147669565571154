import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { queryClient } from "./services/react_query";
import { store, history } from "./store";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";

const tagManagerArgs = {
  gtmId: "GTM-MDLJ9X7H",
  dataLayer: {
    userProject: "Creddy",
  },
};

TagManager.initialize(tagManagerArgs);

const isProduction = window.location.origin === "https://client.creddy.ru";
const isStaging = window.location.origin === "https://stg01.creddy.ru";

if (isProduction || isStaging) {
  Sentry.init({
    dsn: "https://a1188da84ffd6800357051d77398882a@o4508200176844800.ingest.de.sentry.io/4508200179269712",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: isProduction ? "production" : "development",
  });

  console.log(
    `Sentry инициализирован для ${
      isProduction ? "production" : "staging"
    } окружения с environment=${isProduction ? "production" : "development"}`
  );
} else {
  console.log(
    "Sentry не инициализирован: текущий URL не является production или staging."
  );
}

// Sentry.init({
//   dsn: "https://a1188da84ffd6800357051d77398882a@o4508200176844800.ingest.de.sentry.io/4508200179269712",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
      <ReactQueryDevtools
        position={"bottom-right"}
        toggleButtonProps={{
          style:
            process.env.REACT_APP_ENVIRONMENT === "prod"
              ? { display: "none" }
              : undefined,
        }}
      />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
