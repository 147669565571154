import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { LoadingPage } from "../../LoadingPage";
import {
  applicationType,
  passportType,
} from "../../../../../../types/application/types";
import { Loader } from "../../../../../ui/Loader";
import { Button } from "../../../../../ui/Button";
import styles from "./index.module.css";
import { lsController } from "../../../../../../store/auxiliary/localStorage";
import { Icons } from "assets/icons";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { sendMetrics } from "../../../../../../metrics";

type propsType = {};
export const ScoringAndLoanDisbursement: FC<propsType> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const application_id_local = lsController.get("application_id");

  const query = useQuery(
    "CheckingCar",
    () =>
      API.main.application.getApplication({
        applicationId: application_id || application_id_local,
      }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;

        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );

        if (application?.application_type === "signing_loan_contract_filling") {
          history.push(routes.application.code.contract);
        }

        if (application?.application_type === "need_replace_photo_filling") {
          history.push(routes.application.repeated_photos);
        }

        if (application?.application_type === "order_filling") {
          history.push(routes.application.loan_decision);
        } else if (application?.application_type === "refusal_final") {
          sendMetrics("common-client_otkaz-po-zayavke_common-app", "otkaz-po-zayavke");
          if (application?.refusal_cause === "car") {
            dispatch(
              applicationReducer.actions.setCar({
                car: {
                  ...application?.car,
                  refusal_cause_comment: application?.refusal_cause_comment,
                },
              })
            );
            history.push(routes.application.statuses.refusal_final_bad_car);
          } else {
            dispatch(
              applicationReducer.actions.setError({
                error_message: application?.refusal_cause_comment,
                error_type:
                  application?.refusal_cause as applicationType["error_type"],
              })
            );
            history.push(routes.application.statuses.underwriter_refusal);
          }
        }
        if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.statuses.error_final);
        }
      },
    }
  );
  return (
    <>
      <MobilePageContainer className={styles.form}>
        <LoadingPage
          svgIcon={<Icons.ui.Stacks />}
          title={"Заполняем договор"}
          subtitle={
            <>
              Мы проверяем фото и заполняем договор. Обычно, на это уходит не
              более 30 минут.
              <br /> <br />
              Пришлём СМС о готовности
            </>
          }
          // countdownTime={300}
        />
      </MobilePageContainer>
    </>
  );
};
