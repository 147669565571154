import styles from "./index.module.css";
import React, { useRef, useState } from "react";
import cn from "classnames";
import { applicationType } from "../../../../../types/application/types";
import { UseFormReturn } from "react-hook-form/dist/types";
import { Icons } from "../../../../../assets/icons";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { createFC } from "../../../../../helpers/createFC";
import { useGetBanks } from "../../pages/SPBDefineProcess/useGetBanks";
import { Button } from "../../../../ui/Button";
import { Radio } from "../../../../ui/Radio";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { Select } from "../../../../ui/Selects/Select";

type propsType = {
  data: applicationType;
  form: UseFormReturn<any>;
  variant?: "default" | "banki";
};

export const BankSelection = createFC(({
  data,
  form,
  variant = "default"
}: propsType) => {
  const isBankiRu = lsController.get("lead");

  const [showBanks, setShowBanks] = useState(!data?.bank_card?.bank);

  const bankSelectRef = useRef<HTMLInputElement>(null);

  const { transformedDataForSelect, isLoading } = useGetBanks();

  function getBankId(bankName: string): string | undefined {
    const bank = transformedDataForSelect.find((item) =>
      item?.text?.includes(bankName)
    );

    return bank?.id?.toString();
  }

  const AlfaBankId = getBankId("Альфа-Банк");
  const TinkoffBankId = getBankId("ТБанк");
  const Sber = getBankId("Сбербанк");
  const VTB = getBankId("ВТБ");
  const Sovkombank = getBankId("Совкомбанк");

  return (
    <RoundContainer className={cn(styles.container, {
      [styles.container_banki]: variant === "banki",
    })}>
      {showBanks ? (
        <>
          <div className={styles.popularBanks}>
            <div className={styles.title}>
              {variant === "banki" ? "Выберите банк для выдачи денег" : "Выберите банк"}
            </div>
            <div className={styles.subtitle}>
              Выберите банк, в котором у вас подключен СБП. <br /> Счет должен
              быть открыт на ваше имя
            </div>
            <Radio
              name={"bank_id"}
              value={AlfaBankId}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  <Icons.logos.AlphaBank className={styles.bankIcon} />
                  <div className={styles.bankLabel}>Альфа Банк</div>
                </div>
              }
              primaryColor={isBankiRu ? "blue" : "purple-default"}
            />
            <Radio
              name={"bank_id"}
              value={Sber}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  <Icons.logos.SberBank className={styles.bankIcon} />
                  <div className={styles.bankLabel}>Сбер</div>
                </div>
              }
              primaryColor={isBankiRu ? "blue" : "purple-default"}
            />
            <Radio
              name={"bank_id"}
              value={TinkoffBankId}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  <Icons.logos.TinkoffBank className={styles.bankIcon} />
                  <div className={styles.bankLabel}>ТБанк</div>
                </div>
              }
              primaryColor={isBankiRu ? "blue" : "purple-default"}
            />
            <Radio
              name={"bank_id"}
              value={VTB}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  <Icons.logos.VTBBank className={styles.bankIcon} />
                  <div className={styles.bankLabel}>ВТБ</div>
                </div>
              }
              primaryColor={isBankiRu ? "blue" : "purple-default"}
            />
            <Radio
              name={"bank_id"}
              value={Sovkombank}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  <Icons.logos.SovkomBank className={styles.bankIcon} />
                  <div className={styles.bankLabel}>Совкомбанк</div>
                </div>
              }
              primaryColor={isBankiRu ? "blue" : "purple-default"}
            />
          </div>
          <Select
            name={"select"}
            control={form.control}
            setValue={form.setValue}
            options={transformedDataForSelect}
            selectTitle={"Другой банк"}
            search
            support={<Icons.ui.DropDown />}
            inputElementRef={bankSelectRef}
          />
        </>
      ) : (
        <>
          <div className={styles.selected_bank_title}>Вами выбран банк: {data?.bank_card?.bank}</div>
          <Button
            type={"button"}
            variant={"underline"}
            className={cn(styles.change, {
              [styles.change_blue]: isBankiRu,
            })}
            onClick={() => setShowBanks(true)}
          >
            Изменить
          </Button>
        </>
      )}
    </RoundContainer>
  );
});
