import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import styles from "./index.module.css";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { Button } from "../../../../ui/Button";
import { Loader } from "../../../../ui/Loader";
import { SmartForm } from "../../../../hocs/SmartForm";
import { DocumentsArea } from "../../../../ui/DocumentsArea";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Images } from "assets/img";
import { useHistory } from "react-router-dom";
import { queryClient } from "../../../../../services/react_query";
import { getDocumentInfo } from "../Documents";
import { DocumentApiTypes } from "../../../../../types/documents/types";
import { routes } from "../../../../../routes";
import { carPhotoSchema } from "../CarPhoto/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { replegePhotoSchema } from "./schema";
import { applicationType } from "../../../../../types/application/types";

const getCarPhotoInfo = (car_photos: any[], name: string) => {
  return car_photos?.find((car_photo) => car_photo.photo_type === name);
};

export const RepledgePhoto: FC = () => {
  const history = useHistory();
  const isMobileFromDeviceDetect: boolean =
    process.env.NODE_ENV === "development" ? true : isMobile;
  const dispatch = useDispatch();
  const form = useForm({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(replegePhotoSchema),
  });

  const loading = useSelector(
    processLoading.get(
      applicationSagaActions.sendingReplegePhotoForRecognize.type
    )
  );

  const { documents, car, isLoading, delayPing, application_type } =
    useGetInfoFromApplication(2000);

  const repledgeLoanContractDocs = documents.filter(
    (item) => item.photo_type === "repledge_loan_contract"
  );

  const isFileUploading = useSelector(processLoading.get("file/isUploading"));

  const applicationId: number | string | null = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const submit = () => {
    dispatch(
      applicationSagaActions.sendingReplegePhotoForRecognize({
        applicationId,
      })
    );
    // history.push(routes.application.car_photo);
  };

  const onUploaded = (photoType: string) => {
    delayPing(photoType);
  };

  const renderDocumentArea = (
    isPlusIcon: boolean,
    key: number,
    name: string | JSX.Element,
    documentType: DocumentApiTypes.documentType | "repledge_loan_contract_add",
    fixedFiles: string[],
    placeholderImage: string,
    id?: string
  ) => (
    <div key={key} className={styles.document_container}>
      <div className={styles.documents_title}>{name}</div>
      <DocumentsArea
        applicationType={application_type}
        id={id}
        plusIcon={isPlusIcon}
        onUploaded={onUploaded}
        className={styles.ground_files}
        name={
          documentType === "repledge_loan_contract"
            ? `files.${documentType}.${id}`
            : `files.${documentType}`
        }
        fixedFiles={fixedFiles}
        document_type={documentType}
        applicationId={applicationId}
        uploadedDocument={getDocumentInfo(documents, documentType)}
        placeholderImage={placeholderImage}
        docs={repledgeLoanContractDocs}
      />
    </div>
  );

  return (
    <MobilePageContainer>
      {isLoading || !documents || !applicationId ? (
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      ) : isMobileFromDeviceDetect ? (
        <SmartForm form={form} submit={submit}>
          <div className={styles.title}>Сделайте фото документов</div>
          <div className={styles.subtitle}>
            Это нужно для перевода средств на погашение
          </div>
          <div className={styles.main_subtitle}>
            {/*Документ, фото экрана или скриншот*/}
          </div>

          {renderDocumentArea(
            false,
            0,
            <>
              Скриншот ЛК с суммой полного досрочного <br /> погашения или
              справка о состоянии задолженности
            </>,
            "repledge_certificate_of_debt_status",
            ["Справка о текущей задолженности"],
            Images.documentPhotoPlaceholders.replegeDocument
          )}

          <div className={styles.ground_files_title}>Договор займа</div>

          <div className={styles.file_inputs_grid}>
            {Array.from(repledgeLoanContractDocs, (elem, index) => {
              return renderDocumentArea(
                true,
                index + 1,
                "",
                "repledge_loan_contract",
                ["Договор займа"],
                Images.documentPhotoPlaceholders.backGroundGray,
                elem.id
              );
            })}
            {renderDocumentArea(
              false,
              0,
              "",
              "repledge_loan_contract_add",
              ["Договор займа"],
              Images.documentPhotoPlaceholders.repledgeLoanContractAdd
            )}
          </div>

          <Button
            className={styles.btn}
            loading={loading}
            disabled={
              documents.find(
                (item) =>
                  item.photo_type === "repledge_certificate_of_debt_status"
              )?.type !== "document_photo" ||
              documents.find(
                (item) => item.photo_type === "repledge_loan_contract"
              )?.type !== "document_photo" ||
              isFileUploading
            }
          >
            Перейти к фото машины
          </Button>
        </SmartForm>
      ) : (
        <div>Чтобы продолжить, откройте заявку на телефоне</div>
      )}
    </MobilePageContainer>
  );
};
